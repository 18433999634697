<template>
  <div>

    <div class="card" style="width: 100%;">
      <div class="card-body">
        <h5 class="card-title"><i class="fa-solid fa-bell"></i>  전체 공지</h5>
        <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;"><button type="button"  class="btn btn-primary btn-sm"  @click="writeBoard" v-show="$store.state.grade > 3">등록</button></h6>
        <div class="col-3">

        </div>        
        <p class="card-text">
          <table class="table">
            <colgroup>
              <col width=10%>
              <col width=60%>
              <col width=30%>
          </colgroup>                 
              <thead class="table-dark">
                <tr>
                  <th scope="col-sm-1">No</th>
                  <th scope="col-sm-10">제목</th>
                  <th scope="col-sm-1" >일자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, idx) in nlists" v-bind:key="list.no" @click="detailView(list)">
                  <th scope="row">{{idx + 1}}</th>
                  <td>{{ list.subject.substring(0,30) }}</td>
                  <td>{{ formatDate(list.wdate) }}</td>
                </tr>

              </tbody>
            </table>

        </p>
      </div>
    </div>
    <p></p>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"><i class="fa-solid fa-bell"></i> 전체 공지</h5>      
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p></p>
        <div class="form-group mx-sm-3 mb-2" v-if="isModify">
          <div class="row" style="align-items: baseline;">
            <div class="col-2" style="text-align: right; padding-right: 0px;">
              제목
            </div>
            <div class="col-10">                     
                <input
                  type="text"
                  class="form-control"
                  id="formschool"
                  name="formschool"
                  v-model="form.subject"
                  autocomplete="off"
                  placeholder=""
                  style="width: 90%;"
                />
              </div>
            </div>            
          </div>  

          <div class="form-group mx-sm-3 mb-2" v-else>
            <div class="row" style="align-items: baseline;">
              <div class="col-2" style="text-align: right; padding-right: 0px;">
                제목
              </div>
              <div class="col-10" v-html="getContent(form.subject)">                     

                </div>
              </div>            
            </div>  

          <div class="form-group mx-sm-3 mb-2" v-if="isModify">
            <div class="row" style="align-items: baseline;">
              <div class="col-2" style="text-align: right; padding-right: 0px;">
                내용
              </div>
              <div class="col-10">                                     
                <textarea
                    type="text"
                    class="form-control"
                    id="formrmk"
                    name="formrmk"
                    v-model="form.contents"
                    autocomplete="off"
                    placeholder=""
                    rows="14"
                    style="width: 90%;"
                  ></textarea>
                </div>
              </div> 
              <!-- File upload input -->
              <div class="form-group">
                <label for="fileUpload">파일 첨부</label>
                <input type="file" class="form-control-file" id="fileUpload" @change="handleFileUpload">
              </div>                        
            </div>      
            
            <div class="form-group mx-sm-3 mb-2" v-else>
              <div class="row" style="align-items: baseline;">
                  <div class="col-2" style="text-align: right; padding-right: 0px;">
                      내용
                  </div>
                  <div class="col-10">
                      <div v-html="getContent(form.contents)"></div>
                      <!-- Display file download link if a file is present -->
                      <template v-if="form.files">
                          <a :href="getFileUrl(form.files)" download>
                              다운로드 파일
                          </a>
                      </template>
                  </div>
              </div>
          </div> 

        <div class="modal-footer">                  
          <button type="button" class="btn btn-primary" @click="changeMode" v-show="!isModify && $store.state.grade > 3">수정</button>
          <button type="button" class="btn btn-warning" @click="writeBrd" v-show="isModify && $store.state.grade > 3">저장</button>
          <button type="button" class="btn btn-warning" @click="deleteBrd" v-show="!isModify && $store.state.grade > 3">삭제</button>          
          <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
    </div>


  </div>
</template>

<script>
  import manageService from "../services/manageService";
  import dayjs from 'dayjs';
  import $ from "jquery";

  export default {
      component: {
          dayjs
      },        
    data() {
      return {
        items: [],
        nlists: {},
        slists: {},   
        isNew : false,       
        form : {
          subject : "",
          contents: "",
          writer : this.$store.state.name,
          no : 0,
          category: "3",
          file: null, // Add a new property for file upload
        },
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,     
        classid: this.$store.state.classid,  
        isModify : false,  
      }
    },
    computed:{
      formatDate(){
          return (v)=>{
            return dayjs(v).format("YY.MM.DD");
            }
         },       
   },          
    methods:{
    // Method to get the file download URL
    getFileUrl(fileName) {

      let uploadDir = "";
      const serverAddress = '119.205.233.48';
        // Determine upload directory based on server address
        if (window.location.hostname === serverAddress) {
           uploadDir = '/var/www/html/youthmanna/www/upload/files/'; // Linux server path
        } else {
          uploadDir =  'D:\\work\\vue_work\\youthmanna\\www\\upload\\files\\'; // Windows server path
        }

        // Replace with your actual upload directory path
        //let uploadDir = '/path/to/your/upload/directory/';

        // Construct full file path
        let fileUrl = uploadDir + fileName;

        return fileUrl;
    },      
      handleFileUpload(event) {
        this.form.file = event.target.files[0];
      },      
      deleteBrd(){
        const ddata ={
          no : this.form.no,
          category: "3",
          classid: this.classid,
        }
        manageService
            .deleteBoardData(ddata)
            .then((response) => {
              if(response.data[0] === true){
                  this.$toast.show("글이 삭제되었습니다.", {
                        type: "success",
                        position: "top-right",
                      });
                      this.isModify = false;
                      $("#exampleModal").modal('hide');
                      this.getData();
                    } else {
                      this.$toast.show("글 삭제에 실패했습니다.", {
                        type: "warning",
                        position: "top-right",
                      });
                    }
        })
        .catch((e) => {
        console.log(e);
        });

      },
      getContent(text){
            if(text){
                return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
        },    
      changeMode(){
        this.isModify = true;
      },
      writeBrd(){  //글쓰기
        /*
        const idata ={
          subject : this.form.subject,
          contents : this.form.contents,
          writer : this.form.writer,
          no : this.form.no,
          category: "3",
          classid: this.classid,
        }
          */

        const formData = new FormData();
        formData.append("subject", this.form.subject);
        formData.append("contents", this.form.contents);
        formData.append("writer", this.form.writer);
        formData.append("no", this.form.no);
        formData.append("category", "3");
        formData.append("classid", this.classid);
        if (this.form.file) {
          formData.append("file", this.form.file);
        }

        manageService
            .writerBoardData(formData)
            .then((response) => {

              if(response.data[0] === true){
                  this.$toast.show("글이 등록되었습니다.", {
                        type: "success",
                        position: "top-right",
                      });
                      this.isModify = false;
                      $("#exampleModal").modal('hide');
                      this.getData();
                    } else {
                      this.$toast.show("글 등록에 실패했습니다.", {
                        type: "warning",
                        position: "top-right",
                      });
                    }

        })
        .catch((e) => {
        console.log(e);
        });

      },
          writeBoard(){
            this.isNew = true;
            this.form.subject = "";
            this.form.contents ="";
            this.isModify = true;
            this.form.no = 0;

            $("#exampleModal").modal();
          },
          detailView(item){
            this.form = item;
            this.isNew = false;
            this.isModify = false;

          $("#exampleModal").modal();
          },
          getData(){
            
            const idata ={
              category: "3",
              classid: this.classid,
            }    
              manageService
                  .getboardboardall(idata)
                  .then((response) => {
                  //console.log("getboardDashboard", response.data);
                  this.nlists = response.data;
                  //this.slists = response.data.study;
                  //this.manageLists = response.data.slice(0, this.page);
                  //console.log( this.nearMember);
                  //this.manageLists = response.data;
                  //this.manageListLimit = response.data;
                  //this.pageTotalCount = Math.ceil(response.data.length / this.page);
              })
              .catch((e) => {
              console.log(e);
              });
          },
    },
    created() {
      this.getData();
  },      
  }

</script>
<style scoped>
  .table-dark {
  color: #000;
  background-color: #007bff80;
 }
 .table td, .table th {
    padding: 0.5rem;
} 
</style>